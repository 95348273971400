import { Button, Menu, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";

interface Action {
    onClick: (e: any) => void;
    text: string;
    hide: boolean;
}

interface ActionButtonProps {
    actions: Action[];
    text?: string;
}

const ActionButton = (props: ActionButtonProps) => {
    const { actions, text = "Actions" } = props;

    const getActionMenu = () => {
        if (!actions.length) {
            return;
        }

        let actionList: any = [];

        actions.forEach((action: Action) => {
            if (action.hide) {
                return;
            }

            actionList.push(
                <MenuItem
                    key={action.text}
                    intent="primary"
                    text={action.text}
                    onClick={action.onClick}
                />
            );
        })

        return (
            <Menu>
                {actionList}
            </Menu>
        );
    };

    return (
        <Popover2
            className="inline-block"
            content={getActionMenu()}
            placement="bottom"
        >
            <Button
                alignText="left"
                rightIcon="caret-down"
                text={text}
            />
        </Popover2>
    );
};

export default ActionButton;