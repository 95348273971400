import { NONE_SELECTED_OPTION } from './none-selected';

export const ASSESSMENT_TYPE_MILESTONES = {
    status_purchased: 'Purchased',
    status_in_progress: 'In Progress',
    status_submitted_awaiting_assessor: 'Awaiting Assessor',
    status_submitted_returned: 'Returned',
    status_pending_awaiting_reviewer: 'Awaiting Reviewer',
    status_completed: 'Completed - Current',
    status_completed_expired: 'Completed - Expired',
    status_refunded: 'Refunded',
    status_auto_returned: 'Returned',
    status_instance_assigned_to_assessor: 'Being Assessed',
    status_instance_assigned_to_reviewer: 'Being Reviewed',
};

export const COMPLETED_STATUSES = [
    'status_completed',
    'status_completed_expired',
];

export const STATUS_PURCHASED = {
    label: 'Purchased',
    value: 'status_purchased',
};
export const STATUS_IN_PROGRESS = {
    label: 'In Progress',
    value: 'status_in_progress',
};
export const STATUS_SUBMITTED_AWAITING_ASSESSOR = {
    label: 'Submitted - Awaiting Assessor',
    value: 'status_submitted_awaiting_assessor',
};
export const STATUS_SUBMITTED_RETURNED = {
    label: 'Submitted - Returned with Feedback',
    value: 'status_submitted_returned',
};
export const STATUS_PENDING_AWAITING_REVIEWER = {
    label: 'Submitted - Awaiting Reviewer',
    value: 'status_pending_awaiting_reviewer',
};
export const STATUS_COMPLETED = {
    label: 'Completed - Current',
    value: 'status_completed',
};
export const STATUS_COMPLETED_EXPIRED = {
    label: 'Completed - Expired',
    value: 'status_completed_expired',
};
export const STATUS_REFUNDED = {
    label: 'Refunded',
    value: 'status_refunded',
};
export const STATUS_AUTO_RETURNED = {
    label: 'Auto Returned',
    value: 'status_auto_returned',
};
export const STATUS_ASSIGNED_TO_ASSESSOR = {
    label: 'Submitted - Being Assessed',
    value: 'status_instance_assigned_to_assessor',
};
export const STATUS_ASSIGNED_TO_REVIEWER = {
    label: 'Submitted - Being Reviewed',
    value: 'status_instance_assigned_to_reviewer',
};

export const ASSESSMENT_TYPE_PROSURE = {
    label: 'Prosure Assessment',
    value: 'prosure'
};

export const ASSESSMENT_TYPE_CLIENT = {
    label: 'Client Assessment',
    value: 'client'
};

export const ASSESSMENT_INSTANCE_IN_REVIEW_STATUSES = [
    NONE_SELECTED_OPTION,
    STATUS_SUBMITTED_AWAITING_ASSESSOR,
    STATUS_SUBMITTED_RETURNED,
    STATUS_PENDING_AWAITING_REVIEWER,
    STATUS_ASSIGNED_TO_ASSESSOR,
    STATUS_ASSIGNED_TO_REVIEWER,
];

export const ASSESSMENT_INSTANCE_IN_REVIEW_STATUSES_CLIENT = [
    NONE_SELECTED_OPTION,
    STATUS_SUBMITTED_AWAITING_ASSESSOR,
    STATUS_SUBMITTED_RETURNED,
    STATUS_ASSIGNED_TO_ASSESSOR,
];

export const ASSESSMENT_INSTANCE_STATUS_OPTIONS = [
    NONE_SELECTED_OPTION,
    STATUS_PURCHASED,
    STATUS_IN_PROGRESS,
    STATUS_SUBMITTED_AWAITING_ASSESSOR,
    STATUS_SUBMITTED_RETURNED,
    STATUS_PENDING_AWAITING_REVIEWER,
    STATUS_COMPLETED,
    STATUS_COMPLETED_EXPIRED,
    STATUS_REFUNDED,
    STATUS_AUTO_RETURNED,
    STATUS_ASSIGNED_TO_ASSESSOR,
    STATUS_ASSIGNED_TO_REVIEWER,
];

export const ASSESSMENT_INSTANCE_TYPE_FILTER_OPTIONS = [
    NONE_SELECTED_OPTION,
    ASSESSMENT_TYPE_CLIENT,
    ASSESSMENT_TYPE_PROSURE
]

export const IN_PROGRESS_STATUSES = [
    'status_purchased',
    'status_in_progress',
    'status_submitted_awaiting_assessor',
    'status_submitted_returned',
    'status_pending_awaiting_reviewer',
    'status_auto_returned',
    'status_instance_assigned_to_assessor',
    'status_instance_assigned_to_reviewer',
];
