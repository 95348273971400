import { Tab, Tabs } from "@blueprintjs/core";
import { Link, Redirect, Switch } from "react-router-dom";

import TemplateDetails from "components/admin/email/TemplateDetails";
import { Icon, ProtectedRoute } from "components/elements";
import AssessmentTypeInstanceRenewalsList from "components/review/assessment-types/list/renewals/AssessmentTypeInstanceRenewalList";
import RenewalEmailTemplateList from "components/review/assessment-types/list/renewals/RenewalEmailTemplateList";
import { Routing } from "service";

const AssessmentTypeInstanceRenewals = (props: any) => {
    const {
        collection,
        load,
        filter,
        isLoading,
        defaultFilter,
        match,
        location
    } = props;

    const tabId = Routing.getSelectedTabId(location.pathname, match.url);

    return (
        <div className="page AssessmentTypeInstanceRenewals">
            <div className="no-styling page-wrapper">
                <Tabs renderActiveTabPanelOnly={true} selectedTabId={tabId}>
                    <Tab id='/list'>
                        <Link to={`${match.url}/list`}>
                            <Icon icon="info" />
                            Renewals
                        </Link>
                    </Tab>
                    <Tab id='/emails'>
                        <Link to={`${match.url}/emails`}>
                            <Icon icon="envelope" />
                            Emails
                        </Link>
                    </Tab>
                </Tabs>
                <div className="TabContent">
                    <Switch>
                        <ProtectedRoute
                            path={`${match.url}/list`}
                            component={AssessmentTypeInstanceRenewalsList}
                            load={load}
                            collection={collection}
                            isLoading={isLoading}
                            filter={filter}
                            defaultFilter={defaultFilter}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/emails`}
                            component={RenewalEmailTemplateList}
                            exact
                        />
                        <ProtectedRoute
                            path={`${match.url}/emails/:id`}
                            component={TemplateDetails}
                        />
                        <Redirect to={`${match.url}/list`} />
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default AssessmentTypeInstanceRenewals;