import { Button, Classes, Dialog, FormGroup, TextArea } from "@blueprintjs/core";
import { FormEvent, useState } from "react";

import { HTMLSelect } from "components/elements";
import { NoRenewalUpdate, RENEWAL_UPDATE_DIALOG_REASONS } from "constants/assessmentTypeInstance";
import { NONE_SELECTED_OPTION } from "constants/none-selected";
import { AssessmentTypeInstanceData } from "interface";

import { toast } from "react-toastify";
import { InstanceService } from "service";
import styles from './UpdateRenewalDialog.module.scss';

interface UpdateRenewalDialogProps {
    isOpen: boolean;
    onClose?: Function;
    onSuccess?: Function;
    assessmentType: AssessmentTypeInstanceData|null
}

const UpdateRenewalDialog = (props: UpdateRenewalDialogProps) => {
    const { assessmentType, isOpen, onClose, onSuccess } = props;

    const [update, setUpdate] = useState<NoRenewalUpdate>({} as NoRenewalUpdate);

    const handleOnClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleSubmit = () => {
        if (!assessmentType) {
            return;
        }

        InstanceService.noRenewalUpdate(assessmentType, update)
            .then(() => {
                toast.success('Assessment marked to not renew')

                if (onSuccess) {
                    onSuccess();
                }
            })
            .catch(() => toast.error('Unable to mark assesment to not be renewed'));


        handleOnClose();
    };

    return (
        <Dialog
            className={styles.renewalUpdateDialog}
            title="Update Renewal"
            isOpen={isOpen}
            autoFocus
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            usePortal={true}
            onClose={handleOnClose}
        >
            <form onSubmit={handleSubmit}>
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                        label="Reason For Not Renewal"
                        inline={true}
                        className="form-fill required"
                        labelFor="reason"
                    >
                        <HTMLSelect
                            id="reason"
                            fill={true}
                            options={[
                                NONE_SELECTED_OPTION,
                                ...RENEWAL_UPDATE_DIALOG_REASONS
                            ]}
                            onChange={(e: any) => {
                                setUpdate({
                                    comment: '',
                                    reason: e.target.value
                                });
                            }}
                            required
                        />
                    </FormGroup>
                    {update.reason === 'Other' && (
                        <FormGroup
                            label="Details"
                            inline={true}
                            className="form-fill required"
                            labelFor="Details"
                        >
                            <TextArea
                                placeholder="Details"
                                minLength={1}
                                maxLength={500}
                                value={update.comment}
                                required
                                onChange={(e: FormEvent<any>) => {
                                    const { value } = e.target as HTMLInputElement;
                                    setUpdate({
                                        ...update,
                                        comment: value
                                    });
                                }}
                            />
                        </FormGroup>
                    )}
                    <div className={Classes.DIALOG_FOOTER}>
                        <Button
                            type="button"
                            className="float-left"
                            onClick={handleOnClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            intent="success"
                            className="float-right"
                        >
                            Update Renewal
                        </Button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export default UpdateRenewalDialog;
